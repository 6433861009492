nav {
  transition: top 0.5s ease-in-out;
}

.nav-up {
  top: -40px;
}

button {
  .button {
    border-radius: 4px;
  }
}

a:hover {
  text-decoration: underline;
}

mark {
  background-color: rgba(255, 204, 97, 0.5);
  color: black;
}

h1.pageHeader {
  font-weight: 200;
  text-transform: uppercase;
  font-size: 3rem;
  margin-top: 3rem;
}

h1.landingHeader {
  font-weight: 300;
  font-size: 2.8rem;
}

h2.landingHeader {
  font-size: 2.2rem;
}

p.landingHeader {
  font-size: 1.4rem;
  font-weight: bold;
}

h2.bodyHeader {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3rem;
  text-align: left;
}

p.landingP {
  padding-top: 0.7rem;
  padding-bottom: 0.3rem;
}

p.credit {
  font-size: 0.6rem;
}

.add3remmargin {
  margin-bottom: 3rem;
}

h3.text-right {
  font-weight: 300;
  font-size: 1.2rem;
}

p.text-right {
  font-size: 0.8rem;
}

h3.principles {
  font-weight: 200;
  text-align: center;
}

.top-bar {
  margin-bottom: 2rem;
  height: 3rem;
  transition: top 0.5s ease-in-out;
  /*  background-color: $orange;
  box-shadow: 0 0px 0.5rem rgba(20, 20, 20, .4);*/

  .menu-text {
    color: rgba(20, 20, 20, 0);
    transition: all .2s ease-in-out;
    padding: 0.5rem 1rem;
    //visibility: hidden;
  }

  a {
    color: rgba(20, 20, 20, 1);
    transition: all .2s ease-in-out;
  }

  a:hover {
    text-decoration: overline;
    transform: scale(1.1);
  }

  .contactMenu {
    padding: 0.2rem 1rem;
  }
}

.menu li .social-menu {
  padding: 0.5rem 1rem;
}

ul.menu li:active {
  border-bottom: 2px #EDC951 solid;
}

.mailLink {
  color: $orange;
}

#workReason p.confident {
  color: black;
  margin-top: 3rem;
}

#mainInDex {
  background-image: url(../img/main-bg.jpg) !important;
  background-size: cover;
  background-position: left center;
  background-color: $orange;
  min-height: 100%;
  vertical-align: middle;
}

#headerBox {
  display: table-cell;
  vertical-align: middle;
  padding-top: 4rem;
}

.introText {
  position: absolute;
  top: 30%;
  color: $white;

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.6em;
    margin-top: 2rem;
    z-index: 2;
  }

  img {
    margin-left: 0.2rem;
    z-index: 0;
  }
}

a {
  color: black;
}

p a {
  text-decoration: underline;
}

a.reverse {
  color: white;
  text-decoration: underline;
}

.cfa {
  //  background-image: url(../img/background_bottom.png);
  background-position: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 2rem;
}


/* Portfolio Section */

#workNav {
  margin-top: 1em;
  margin-bottom: 2em;

  h2 {
    color: $medium-gray;
  }
}

#workNav .workleft {
  margin-left: 0px;
  background-size: 4.3rem 4.3rem;
}

#workNav .workright {
  margin-right: 0px;

  h1 {
    transform: scale(0.8);
    transform-origin: right;
    color: $black;
    transition-timing-function: ease;
    transition: 0.2s;
  }
}

#headerBox {
  p.headerCTA {
    margin-top: 3rem;
    font-size: 1rem;
    font-weight: 600;
  }
}

.headingCTAimg {
  margin-top: 1rem;
}

#workNav p {
  color: $black;
  margin-bottom: 0px;
}

#workNav h1 {
  color: #0a0a0a;
  line-height: 1.2em;
}

@media screen and (max-width: 599px) {
  #workNav {
    margin-top: 2rem;
  }

  #workNav .workleft {
    padding-left: 20px;
    background-size: 90% 90%;
    background-position: -60px 0;
  }

  #workNav .workright {
    padding-right: 20px;
    background-size: 90% 90%;
    background-position: 80px 0;
  }

  #workNav p {
    font-size: 1rem;
  }

  #workNav h1 {
    font-size: 2rem;
  }

  #workNav h2 {
    font-size: 1.5rem;
  }
}

#workFeature {
  margin-top: 8rem;
  margin-bottom: 4rem;
  z-index: 4;
}

#workFeatureThumb {
  margin-bottom: 4rem;
  z-index: 4;
  margin-top: 4rem;
}

#workReason {
  margin-bottom: 4rem;
}

#workReason p {
  color: $medium-gray;
}

#matric {
  margin-bottom: 4rem;
}

tbody {
  border: none !important;
}

tr {
  vertical-align: top;
  border: none;
  background-color: #fefefe !important;
  border-top: solid 1px #cacaca;
}

.callout tr {
  background-color: $orange !important;
  border-top: solid 1px $white;
}

td.intro {
  font-size: 2rem;
  color: #8a8a8a;
  line-height: 1.2em;
  font-weight: 200;
}

@media screen and (max-width: 599px) {
  td.intro {
    font-size: 1rem;
    color: #0a0a0a;
    line-height: 1.2em;
    font-weight: 400;
  }

  td {
    font-size: 0.8em;
  }
}

#offers {
  margin-bottom: 4rem;
}

.callout {
  background-color: $orange;
  color: $white;
}

.callout h3 {
  color: #fefefe;
}

.callout td.intro {
  color: #fefefe;
  font-size: 1.2em;
  font-weight: 400;
}

.orbit {
  p {
    padding: 0 2rem;
  }

  h3 {
    font-size: 1rem;
    color: $dark-gray;
  }
}

.orbit-container {
  height: auto;
  width: auto;
  margin-bottom: 4rem;
}

#workFeature {
  margin-bottom: 4rem;
  z-index: 4;
}

.portfolioList {
  border: 1px solid $orange;
  position: relative;
  margin-bottom: 20px;
  background-color: $white;
  z-index: 3;

  &:hover {
    box-shadow: 0 5px 20px rgba(20, 20, 20, .2);
    transform: translateY(-6px) scale(1.01);
    transition-timing-function: ease;
    transition: 0.2s;
    border-radius: 1rem 1rem 1rem 1rem;

    .h3 {
      background-position: -100% 0;
    }

    img {
      border-radius: 1rem 1rem 0 0;
      //transform: translateY(-6px) scale(1.01);
      transition-timing-function: ease;
      transition: 0.2s;
    }
  }

  h3 {
    margin: 10px 0.5rem 0 0;
  }

  p {
    margin: 0px 0.5rem 0 20px;
    padding-bottom: 20px
  }

  img.arrow {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.awardList {
  border: 1px solid $orange;
  position: relative;
  margin-bottom: 20px;
  background-color: $white;
  z-index: 3;

  &:hover {
    box-shadow: 0 5px 20px rgba(20, 20, 20, .2);
    transform: translateY(-6px) scale(1.01);
    transition-timing-function: ease;
    transition: 0.2s;
    border-radius: 1rem 1rem 1rem 1rem;

    img {
      border-radius: 1rem;
      //transform: translateY(-6px) scale(1.01);
      transition-timing-function: ease;
      transition: 0.2s;
    }
  }
}

.padBottom {
  padding-bottom: 1rem !important;
}

.slideUp {
  @include mui-animation(slide, fade);
}

.ccText {
  position: relative;
  color: $dark-orange;

  #searchCatagory {
    position: absolute;
    top: 32%;
    width: 25%;
    font-size: 1em;
  }

  #visualCue {
    position: absolute;
    top: 42%;
    right: 0;
    width: 30%;
    text-align: right;
  }

  #customizeCollection {
    position: absolute;
    top: 72%;
    right: 0;
    width: 30%;
    text-align: right;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portrait {
  background-image: url("../img/aboutPrak.jpg");
  padding: 1em 0 1em 0;
  background-size: cover;
  color: $black;
}

.sticky.is-stuck {
  background-color: white;
  box-shadow: 0 5px 20px rgba(10, 10, 10, .1);

  .menu-text {
    visibility: visible;
    color: white;
  }

  a {
    color: rgba(20, 20, 20, 1);
  }
}

body {
  background-image: url("../img/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.passwordRequired {
  position: absolute;
  top: 1rem;
  right: 0;
  background-color: $orange;
  color: $white;
  padding: 0.5rem;
}

.introduction {
  font-size: 1.3rem;
  color: $black;
}

.navSelected {
  text-decoration: overline;
}

#workNav h1.text-right {
  padding-top: 1rem;

  @include breakpoint (small medium) {
    text-align: right;
  }
}

.top-bar-left {
  @include breakpoint (small) {
    width: 50%;
  }
}

.top-bar-right {
  margin-top: 0;

  @include breakpoint (small) {
    width: 50%;
  }

  @include breakpoint (medium large) {
    width: auto;
  }
}

#workFeature a:hover {
  text-decoration: none;
}

.iso_bg img {
  perspective: 2000px;
}

.ar_icon {
  position: absolute;
  width: 15%;
  z-index: 3;
  top: 70%;
  right: 35%;
}

.merlin {
  position: absolute;
  width: 20%;
  z-index: 2;
  top: 6%;
  left: 1%;
}

.coffee {
  position: absolute;
  width: 20%;
  z-index: 3;
  top: 22%;
  left: 20%;
}

.mentor {
  position: absolute;
  width: 36%;
  z-index: 3;
  top: 40%;
  left: 60%;
}

.security_icon {
  position: absolute;
  width: 17%;
  z-index: 0;
  top: 15%;
  right: 15%;
}

.bluetooth_icon {
  position: absolute;
  width: 16%;
  z-index: 3;
  top: 10%;
  left: 17%;
}

.chat_icon {
  position: absolute;
  width: 18%;
  z-index: 3;
  top: 50%;
  left: 15%;
}

.ml_icon {
  position: absolute;
  width: 16%;
  z-index: 3;
  top: 0%;
  left: 55%;
}

.nfc_icon {
  position: absolute;
  width: 16%;
  z-index: 3;
  top: 35%;
  right: 10%;
}

.iso_bg {
  position: absolute;
  z-index: 2;
}

#partnerWrapper {
  margin-top: 8em;
}

.showcase {
  margin-top: 8rem;
}


.contact {
  font-size: 3rem;
}

.quote {
  position: relative;
  font-style: normal;
  padding-top: 1em;
  padding-bottom: 1em;
  color: $primary-color;
  margin: 3rem 0 6rem 0;
  font-size: 1.4rem;

  &:before {
    font-style: normal;
    content: '\201C';
    position: absolute;
    top: -2rem;
    left: -3rem;
    color: darken($white, 7%);
    font-size: 6em;
    z-index: -1;
  }
}

.quote_sub {
  position: relative;
  color: $primary-color;
  margin: 1rem 0 1rem 0;
  font-size: 1.4rem;
}

.beforeafter {
  border: solid 1px;

}

.extraPadding {
  margin-top: 6rem;
}

.workSection {
  padding-bottom: 4rem;
}

/* Portfolio Section */
.jobRole {
  .portRoleSection {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .portTitle {
    font-weight: 600;
    margin-bottom: 0;
  }

  hr {
    margin: 0 0 1rem 0;
  }
}

.portfolioPadding {
  padding: 4rem 0 4rem 0;

  h2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2em;
  }

  h3 {
    font-size: 2rem;
    font-weight: 300;
    color: $medium-gray;
  }

  .paddingBottom {
    padding-bottom: 3rem;
  }

  .paddingTop {
    padding-top: 3rem;
  }

  li {
    margin: 1rem 0 1rem 0;
  }
}

.statcallout {
  h2 {
    text-align: center;
    font-size: 5rem;
    font-weight: 400;
    color: $primary-color;
    margin-bottom: -0.5rem;
  }

  p {
    text-align: center;
  }
}

/* Background Colors */
.blue {
  background-color: $skype-blue;
  color: white;
}

.teams {
  background-color: $Teams;
  color: white;
}

.NovoBlue {
  background-color: $NovoBlue;
  color: white;
}

.designPrincipal {
  padding: 1rem;

  img {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  }

  h3 {
    padding-top: 1rem;
    text-align: center;
  }
}

.orbit-wrapper {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
}

.alignCenter {
  text-align: center;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
  border: 1px solid #e0e0e0;
}

textarea {
  height: 6rem;
}

.footer {
  font-size: 0.8rem;
  margin-right: 2rem;
}

hr {
  height: 1px;
  background-color: #ccc;
  border: none;
  margin: 6rem 0 6rem 0;
}

//About
#about {
  h4:first-of-type {
    padding-top: 0;
  }

  h4 {
    padding-top: 1.2rem;
    font-weight: 800;
  }

  .sideAbout p {
    margin: 0.4rem 0 0 0;
    font-size: 0.9rem;
    line-height: 1.1rem;

    a {
      text-decoration: none;
    }
  }

  h3 {
    margin-top: 3rem;
  }

  img {
    margin: 2rem 0 2rem 0;
  }
}

#DesignProcess {
  .sticky.is-stuck {
    background-color: white;
    box-shadow: none;
    padding-top: 4rem;
    z-index: 2;
  }

  padding-top: 1rem;

  h3 {
    margin-top: 0;
    padding-top: 4rem;
  }

  #Empathy {
    padding-top: 0;
  }

  a {
    text-decoration: none;
  }

  #DesignProcessNav {
    a:active {
      text-decoration: underline;
    }
  }
}
